import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "./Icon";

const Tab = ({ title, to, options = {} }) => {
  const onClick = options?.onClick;
  const icon = options?.icon || "";
  const active = options?.active;
  const extraClass = options.extraClass || "";

  if (to) {
    return (
      <NavLink
        className={`${extraClass} flex flex-row w-1/2 justify-center items-center text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none hover:bg-gray-50 rounded-t-xl`}
        activeClassName="text-blue-500 border-b-2 font-medium border-blue-500 bg-gray-100"
        to={to}
        onClick={onClick}
      >
        <Icon className="h-6 w-6" icon={icon} />
        <span className={`${icon ? "ml-4" : ""}`}>{title}</span>
      </NavLink>
    );
  } else {
    return (
      <div
        className={`${extraClass} flex flex-row w-1/2 justify-center items-center text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none hover:bg-gray-50 rounded-t-xl ${
          active
            ? "text-blue-500 border-b-2 font-medium border-blue-500 bg-gray-100"
            : ""
        }`}
        onClick={onClick}
      >
        <Icon className="h-6 w-6" icon={icon} />
        <span className={`${icon ? "ml-4" : ""}`}>{title}</span>
      </div>
    );
  }
};

export default Tab;
